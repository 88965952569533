document.addEventListener("DOMContentLoaded", function () {
  var myAudio = document.getElementById("audio");
  myAudio.volume = 0.03;
  function togglePlay() {
    return myAudio.paused ? myAudio.play() : myAudio.pause();
  }

  const musicBtn = document.querySelector(".music-btn");
  musicBtn.addEventListener("click", () => {
    musicBtn.classList.toggle("music-btn--playing");
    togglePlay();
  });
});
